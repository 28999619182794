import { Button, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSnackBar } from "../../../../hooks/useSnackBar";
import { getCvService, getTypeDocumentService, postSaveProfileService } from "../../../../services/repositorio.api";
import Loading from "../../../common/Loading";
import { SnackBarGenerico } from "../../../common/SnackBarGenerico";

const FormPersional = props => {
    const { data, id, repo_id } = props;

    const [formData, setformData] = useState(null)
    const [cv, setCv] = useState(null)
    const [dataCv, setdataCv] = useState(null)
    const [typeDocument, setTypeDocument] = useState(null)
    const [loading, setloading] = useState(null)
    const [snackBar, closeSnackBar, openSnackBar] = useSnackBar();


    useEffect(() => {
        if (formData === null) {
            setformData(data)
        }
    }, [formData])

    useEffect(() => {
        if (typeDocument === null) {
            getTypeDocument()
        }
    }, [typeDocument])

    useEffect(() => {
        if (!!data.cv) {
            getCv()
        }
    }, [])

    const handleCv = async (e) => {
        const result = await toBase64(e.target.files[0])
        setCv(result)
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const getTypeDocument = async () => {
        const response = await getTypeDocumentService()
        setTypeDocument(response)
    }

    const getCv = async () => {
        const response = await getCvService(repo_id)
        setdataCv(`data:${response.mimetype};base64,${response.base64}`)
    }

    const handleSubmit = async () => {
        setloading(true)
        const data = validateInputs(formData)
        const response = await postSaveProfileService(repo_id, data)
        if (response.status) {
            openSnackBar(
                "error",
                response.error
            );
        }
        else{
            openSnackBar(
                "success",
                response
            );
        }
        setloading(null)
    }

    const handleFile = () => {
        setformData({...formData, cv: false})
    }

    const handleOpenFile = () => {
        console.log(dataCv);
        window.open(dataCv)
    }

    const validateInputs = (data) => {
        const newData = {}

        if(data.cv) newData.cv = data.cv
        if(data.email) newData.email = data.email
        if(data.email_laboral) newData.email_laboral = data.email_laboral
        if(data.fecha_nacimiento) newData.fecha_nacimiento = data.fecha_nacimiento
        if(data.genero) newData.genero = data.genero
        if(data.l10n_latam_identification_type_id) newData.l10n_latam_identification_type_id = data.l10n_latam_identification_type_id
        if(data.mobile) newData.mobile = data.mobile
        if(data.nacionalidad) newData.nacionalidad = data.nacionalidad
        if(data.phone) newData.phone = data.phone
        if(data.street) newData.street = data.street
        if(data.vat) newData.vat = data.vat
        if(data.vencimiento_pasaporte) newData.vencimiento_pasaporte = data.vencimiento_pasaporte
        if(cv) newData.cv = cv.split("base64,")[1]

        return newData
    }

    if (formData === null) {
        return <Loading />
    }

    return <><Container>
        <Col>
            <FormGroup>
                <TextFieldFake
                    value={formData.street === false ? "" : formData.street}
                    label="Dirección"
                    onChange={e => setformData(state => ({ ...state, street: e.target.value }))}
                />
            </FormGroup>

            <FormGroup>
                <TextFieldFake
                    value={formData.nacionalidad === false ? "" : formData.nacionalidad}
                    label="Nacionalidad"
                    onChange={e => setformData(state => ({ ...state, nacionalidad: e.target.value }))}
                />
            </FormGroup>

            <FormGroup>
                <InputLabel>Tipo de documento</InputLabel>
                <SelectFake
                    value={formData.l10n_latam_identification_type_id}
                    onChange={e => setformData(state => ({ ...state, l10n_latam_identification_type_id: e.target.value }))}
                >
                    {typeDocument && (
                        typeDocument.map(item => <MenuItem value={item.id}>{item.nombre}</MenuItem>)
                    )}
                </SelectFake>
            </FormGroup>

            <FormGroup>
                <TextFieldFake
                    value={formData.vat === false ? "" : formData.vat}
                    label="Documento"
                    onChange={e => setformData(state => ({ ...state, vat: e.target.value }))}
                />
            </FormGroup>

            <FormGroup>
                <TextFieldFake
                    value={formData.vencimiento_pasaporte === false ? new Date() : formData.vencimiento_pasaporte}
                    label="Fecha venciomiento pasaporte"
                    type="date"
                    onChange={e => setformData(state => ({ ...state, vencimiento_pasaporte: e.target.value }))}
                />
            </FormGroup>

            <FormGroup>
                <TextFieldFake
                    value={formData.fecha_nacimiento === false ? new Date() : formData.fecha_nacimiento}
                    label="Fecha de nacimiento"
                    type="date"
                    onChange={e => setformData(state => ({ ...state, fecha_nacimiento: e.target.value }))}
                />
            </FormGroup>

        </Col>
        <Col>
            <FormGroup>
                <TextFieldFake
                    value={formData.phone === false ? "" : formData.phone}
                    label="Télefono"
                    onChange={e => setformData(state => ({ ...state, phone: e.target.value }))}
                />
            </FormGroup>

            <FormGroup>
                <TextFieldFake
                    value={formData.mobile === false ? "" : formData.mobile}
                    label="Móvil"
                    onChange={e => setformData(state => ({ ...state, mobile: e.target.value }))}
                />
            </FormGroup>

            <FormGroup>
                <TextFieldFake
                    value={formData.email === false ? "" : formData.email}
                    label="Correo electrónico (personal)"
                    onChange={e => setformData(state => ({ ...state, email: e.target.value }))}
                />
            </FormGroup>

            <FormGroup>
                <TextFieldFake
                    value={formData.email_laboral === false ? "" : formData.email_laboral}
                    label="Correo electrónico (laboral)"
                    onChange={e => setformData(state => ({ ...state, email_laboral: e.target.value }))}
                />
            </FormGroup>

            <FormGroup>
                <InputLabel>Género</InputLabel>
                <SelectFake
                    value={formData.genero}
                    onChange={e => setformData(state => ({ ...state, genero: e.target.value }))}
                >
                    <MenuItem value={"masculino"}>Masculino</MenuItem>
                    <MenuItem value={"femenino"}>Femenino</MenuItem>
                </SelectFake>
            </FormGroup>

            <FormGroup>
                {!formData.cv ? (
                    <>
                        <InputLabel>CV</InputLabel>
                        <TextFieldFake
                            type="file"
                            onChange={handleCv}
                        />
                    </>
                ) : (
                    <CvWrapper>
                        <Button onClick={handleOpenFile} color="dark" variant="contained" >Descargar CV</Button>&nbsp;
                        <Button onClick={handleFile} color="dark" variant="outlined" >Volver a cargar</Button>
                    </CvWrapper>
                )}
                
            </FormGroup>
        </Col>
    </Container>
        <ZoneSubmit>
            {loading === null ? 
            (<ButtonFake disabled={loading !== null || !formData} onClick={handleSubmit}>GUARDAR</ButtonFake>)
            :
            (<Loading/>)
            }
        </ZoneSubmit>
        <SnackBarGenerico
            show={snackBar.show}
            handleClose={closeSnackBar}
            message={snackBar.message}
            variant={snackBar.variant}
        />
    </>
}

export default FormPersional;

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`

const Col = styled.div`
    width: 50%;
    height: 100%;
    padding: 1rem;
`

const TextFieldFake = styled(TextField)`
    width: 100%;
`

const SelectFake = styled(Select)`
    width: 100%;
    margin-top: 15px;
`

const FormGroup = styled.div`
    padding: 15px;
`

const ZoneSubmit = styled.div`
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const ButtonFake = styled.button`
    color: white;
    background-color: #de4d2c;
    padding: 9px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    font-weight: 700;
    font-size: 18px;
    border-radius: 3px;
    transition: all .3s ease;
    cursor: pointer;
    :disabled{
        background-color: #404040;
        cursor: inherit;
    }
    :hover{
        background-color: #a33e27;
        :disabled{
            background-color: #404040;
            cursor: inherit;
    }
    }
`

const CvWrapper = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
`