import React from "react";
import styled from "styled-components";

const Mount = (props) => {

    const { value } = props

    return <>
        <Title>Por cobrar</Title>
        <Data>Moneda local: {value.smontoAC}</Data>
        <Data>USD: ${value.smontoACD}</Data>
    </>
}

export default Mount

const Title = styled.h4`
    font-size: 14px;
    font-weight: 800;
    color: #de4d2c;
`

const Data = styled.h4`
    font-size: 14px;
    font-weight: 800;
    color: #303030;
    margin-top: 5px;
`